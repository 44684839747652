import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import CreateCust from './components/CreateCust';
import EditCust from './components/EditCust';
import ListCust from './components/ListCust';
import Login from './components/Login';
import PrintView from './components/PrintView';
import ItinernaryView from './components/ItinernaryView';
function App() {
    const loginToken = localStorage.getItem('loginToken');
    
  return (
      <div className="App">
          <BrowserRouter>
              <Routes>
                  {loginToken && <Route path="/edit/:id" element={<EditCust />} />}
                  {loginToken && <Route exact path="/" element={<ListCust />} />}
                  {loginToken && <Route exact path="/printinvoice/:id" element={<PrintView />} />}
                  {loginToken && <Route exact path="/itinernary/:id" element={<ItinernaryView />} />}
                  {!loginToken && <Route path="*" element={<Login />} />}
                  
              </Routes>
          </BrowserRouter>
      
    </div>
  );
}

export default App;
