import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams ,Link} from "react-router-dom";
import Header from './Header';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Tooltip from '@mui/material/Tooltip';
export default function EditCust() {
    const navigate = useNavigate();
    const [free, setFree] = useState({
        p_name: "",
        p_age: "",
        p_gender: "",
        passport: "",
        pydate: "",
        comment: "",
        amount: ""
    });
    const [inputs1, setInputs1] = useState(false);
    const [inputs2, setInputs2] = useState(false);
    const [inputs3, setInputs3] = useState(false);
    const [inputs4, setInputs4] = useState(false);
    const [inputs5, setInputs5] = useState(false);
    const [inputs6, setInputs6] = useState(false);
    const [inputs7, setInputs7] = useState(false);
    const [total, setTotal] = useState(0);
    const [pay, setPay] = useState(0);
    const { id } = useParams();

    useEffect(() => {
        getCust();
        getPassenger();
        getHotel();
        getPickup();
        getSight();
        getPayment();
        getActivity();
    }, []);
    useEffect(() => {
        var curr = 0;
        if (Array.isArray(inputs3)) {
            Array.from(inputs3).map((h, key) => {
                curr += parseInt(h.cost, 10);
            })
        }
        if (Array.isArray(inputs4)) {
            Array.from(inputs4).map((h, key) => {
                curr += parseInt(h.cost, 10);
            })}
        if (Array.isArray(inputs5)) {
            Array.from(inputs5).map((h, key) => {
                curr += parseInt(h.cost, 10);
            })
        }
        setTotal(curr);
        var recpay = 0;
        if (Array.isArray(inputs6)) {
            Array.from(inputs6).map((h, key) => {
                recpay += parseInt(h.amount, 10);
            })
        }
        setPay(recpay);
        console.log(curr);
        console.log(recpay);
    }, [inputs1]);

    const getCust =async() => {
        const data = { 'userid': localStorage.getItem('userid'),'custid':id,'action':'get_cust_edit' };
        const response = await axios.post(`https://billing.destinationventure.com/api/operations.php`, data);
        if (response.data == 'empty')
            navigate('/');
        setInputs1(response.data);
    }
    const getPassenger = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_passenger_edit' };
        const response = await axios.post(`https://billing.destinationventure.com/api/operations.php`, data);
        
        setInputs2(response.data);
    }
    const getHotel = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_hotel_edit' };
        const response = await axios.post(`https://billing.destinationventure.com/api/operations.php`, data);
        
        setInputs3(response.data);
    }
    const getPickup = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_pickup_edit' };
        const response = await axios.post(`https://billing.destinationventure.com/api/operations.php`, data);
        
        setInputs4(response.data);
    }
    const getSight = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_sight_edit' };
        const response = await axios.post(`https://billing.destinationventure.com/api/operations.php`, data);
        
        setInputs5(response.data);
    }
    const getActivity = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_activity_edit' };
        const response = await axios.post(`https://billing.destinationventure.com/api/operations.php`, data);

        setInputs7(response.data);
    }
    const getPayment = async () => {
        const data = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'get_payment_edit' };
        const response = await axios.post(`https://billing.destinationventure.com/api/operations.php`, data);
        
        setInputs6(response.data);
    }
    const handleChangeFree = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFree(values => ({ ...values, [name]: value }));
    }
    const handleChangeForm1 = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs1(values => ({ ...values, [name]: value }));
    }
    const handleChangeForm2 = (index,event) => {
        const name = event.target.name;
        const value = event.target.value;
        const list = [...inputs2];
        list[index][name] = value;
        setInputs2(list);
    }
    const handleChangeForm3 = (index, event) => {
        const name = event.target.name;
        const value = event.target.value;
        const list = [...inputs3];
        list[index][name] = value;
        setInputs3(list);
    }
    const handleChangeForm4 = (index, event) => {
        const name = event.target.name;
        const value = event.target.value;
        const list = [...inputs4];
        list[index][name] = value;
        setInputs4(list);
    }
    const handleChangeForm5 = (index, event) => {
        const name = event.target.name;
        const value = event.target.value;
        const list = [...inputs5];
        list[index][name] = value;
        setInputs5(list);
    }
    const handleChangeForm6 = (index, event) => {
        const name = event.target.name;
        const value = event.target.value;
        const list = [...inputs6];
        list[index][name] = value;
        setInputs6(list);
    }
    const handleChangeForm7 = (index, event) => {
        const name = event.target.name;
        const value = event.target.value;
        const list = [...inputs7];
        list[index][name] = value;
        setInputs7(list);
    }
    const handleSubmitForm1 = (event) => {
        event.preventDefault();
        const userdata = { 'userid': localStorage.getItem('userid') ,'action':'edit_cust','custid':id};
        const data = { ...inputs1, ...userdata };
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            document.location.reload();
        });
    }
    const updatePassenger = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_passenger'};
        const passenger = { ...inputs2 };
        const data = { ...userdata, ...passenger[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const deletePassenger = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'delete_passenger' };
        const passenger = { ...inputs2 };
        const data = { ...userdata, ...passenger[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const addPassenger = () => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'add_passenger' };
        const data = { ...userdata, ...free}
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const updateHotel = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_hotel' };
        const hotel = { ...inputs3 };
        const data = { ...userdata, ...hotel[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const deleteHotel = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'delete_hotel' };
        const hotel = { ...inputs3 };
        const data = { ...userdata, ...hotel[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const addHotel = () => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'add_hotel' };
        const data = { ...userdata, ...free }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const updatePickup = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_pickup' };
        const pickup = { ...inputs4 };
        const data = { ...userdata, ...pickup[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const deletePickup = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'delete_pickup' };
        const pickup = { ...inputs4 };
        const data = { ...userdata, ...pickup[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const addPickup = () => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'add_pickup' };
        const data = { ...userdata, ...free }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const updateSight = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_sight' };
        const sight = { ...inputs5 };
        const data = { ...userdata, ...sight[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const deleteSight = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'delete_sight' };
        const sight = { ...inputs5 };
        const data = { ...userdata, ...sight[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const addSight = () => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'add_sight' };
        const data = { ...userdata, ...free }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const updateActivity = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_activity' };
        const activity = { ...inputs7 };
        const data = { ...userdata, ...activity[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const deleteActivity = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'delete_activity' };
        const activity = { ...inputs7 };
        const data = { ...userdata, ...activity[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const addActivity = () => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'add_activity' };
        axios.post(`https://billing.destinationventure.com/api/operations.php`, userdata).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const updatePayment = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'update_payment' };
        const payment = { ...inputs6 };
        const data = { ...userdata, ...payment[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const deletePayment = (index) => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'delete_payment' };
        const payment = { ...inputs6 };
        const data = { ...userdata, ...payment[index] }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    const addPayment = () => {
        const userdata = { 'userid': localStorage.getItem('userid'), 'custid': id, 'action': 'add_payment' };
        const data = { ...userdata, ...free }
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            document.location.reload();
        });
    }
    return (
        <div>
            <Header />
            <div style={{ 'text-align': 'left' }}>
                <span style={{ 'margin-left': "10px" }}><b>Total Amount Due : {total - pay}</b></span>
                <Link to={`../../printinvoice/${id}`} style={{ 'float': "right", 'margin-right': "50px" }} className="btn btn-danger float-right">Invoice</Link>
                <Link to={`../../itinernary/${id}`} style={{ 'float': "right", 'margin-right': "50px" }} className="btn btn-success float-right">Itinernary</Link>
            </div>
            <h2>Customer Details</h2>
            <div>
                <form onSubmit={handleSubmitForm1}>
                    <table className="table table-striped table-bordered" >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>GST</th>
                                <th>Currency</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><EditText value={inputs1.c_name} type="text" name="c_name" onChange={handleChangeForm1} /></td>
                                <td><EditText value={inputs1.c_email} type="email" name="c_email" onChange={handleChangeForm1} /></td>
                                <td><EditText value={inputs1.c_phone} type="number" name="c_phone" onChange={handleChangeForm1} /></td>
                                <td><EditText value={inputs1.gst} type="text" name="gst" onChange={handleChangeForm1} /></td>
                                <td><EditText value={inputs1.currency} type="text" name="currency" onChange={handleChangeForm1} /></td>
                                <td><button><Tooltip title="Update"><UpgradeIcon color="primary" /></Tooltip></button></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <br></br>
            <h2>Passenger Details</h2>
            <div>    
                <table className="table table-striped table-bordered" >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Age</th>
                            <th>Gender</th>
                            <th>Passport/ID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        {Array.isArray(inputs2) ?inputs2.map((p, key) =>
                        <tr key={key}>
                            <td><EditText value={p.p_name} type="text" name='p_name' onChange={(evnt) => handleChangeForm2(key, evnt)} /></td>
                            <td><EditText value={p.p_age} type="text" name='p_age' onChange={(evnt) => handleChangeForm2(key, evnt)} /></td>
                            <td><EditText value={p.p_gender} type="text" name='p_gender' onChange={(evnt) => handleChangeForm2(key, evnt)} /></td>
                            <td><EditText value={p.passport} type="text" name='passport' onChange={(evnt) => handleChangeForm2(key, evnt)} /></td>
                            <td>
                                    <button onClick={() => updatePassenger(key)}><Tooltip title="Update"><UpgradeIcon color="primary" /></Tooltip></button>
                                    <button onClick={() => deletePassenger(key)}><Tooltip title="Delete"><DeleteForeverIcon color="secondary"/></Tooltip></button>
                            </td>
                        </tr>
                        ) : null}
                        <tr>
                            <td><EditText value={free.p_name} placeholder="Enter Name" type="text" name="p_name" onChange={handleChangeFree} /></td>
                            <td><EditText value={free.p_age} placeholder="Enter Age" type="text" name="p_age" onChange={handleChangeFree} /></td>
                            <td><EditText value={free.p_gender} placeholder="Enter Gender" type="text" name="p_gender" onChange={handleChangeFree} /></td>
                            <td><EditText value={free.passport} placeholder="Enter Passport Number" type="text" name="passport" onChange={handleChangeFree} /></td>
                            <td>
                                <button onClick={() => addPassenger()}><Tooltip title="Add"><AddTaskIcon color="success" /></Tooltip>Add Passenger</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
            <br></br>
            <h2>Hotel Details</h2>
            <div style={{ 'overflow-x': 'scroll' }}>
                
                <table className="table table-striped table-bordered" style={{ 'width': 'max-content','min-width': '100%' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Check-In</th>
                            <th>Check-Out</th>
                            <th>Room Type</th>
                            <th>Room Qty</th>
                            <th>Meal Plan</th>
                            <th>Cost</th>
                            <th>Confirmation</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(inputs3) ? inputs3.map((h, key) =>
                            <tr key={key}>
                                <td><EditText value={h.h_name} type="text" name='h_name' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td><EditText value={h.checkin} type="date" name='checkin' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td><EditText value={h.checkout} type="date" name='checkout' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td><EditText value={h.room_type} type="text" name='room_type' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td><EditText value={h.room_count} type="text" name='room_count' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td><EditText value={h.meal_plan} type="text" name='meal_plan' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td><EditText value={h.cost} type="text" name='cost' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td><EditText value={h.confirmation} type="text" name='confirmation' onChange={(evnt) => handleChangeForm3(key, evnt)} /></td>
                                <td>
                                    <button onClick={() => updateHotel(key)}><Tooltip title="Update"><UpgradeIcon color="primary" /></Tooltip></button>
                                    <button onClick={() => deleteHotel(key)}><Tooltip title="Delete"><DeleteForeverIcon color="secondary" /></Tooltip></button>
                                </td>
                            </tr>
                        ) : null}
                        
                    </tbody>
                </table>
            </div>
            <br></br>
            <div>
                <button onClick={() => addHotel()}><Tooltip title="Add"><AddTaskIcon color="success" /></Tooltip>Add New Hotel Booking</button>
            </div>
            <br></br>
            <h2>Pickup & Drop Details</h2>
            <div style={{ 'overflow-x': 'scroll' }}>
                
                <table className="table table-striped table-bordered" style={{ 'width': 'max-content','min-width': '100%' }}>
                    <thead>
                        <tr>
                            <th>Pickup Point</th>
                            <th>Drop Point</th>
                            <th>Date</th>
                            <th>Time/ID</th>
                            <th>Vehicle</th>
                            <th>Quantity</th>
                            <th>Details</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(inputs4) ? inputs4.map((p, key) =>
                            <tr key={key}>
                                <td><EditText value={p.pickup} type="text" name='pickup' onChange={(evnt) => handleChangeForm4(key, evnt)} /></td>
                                <td><EditText value={p.dropto} type="text" name='dropto' onChange={(evnt) => handleChangeForm4(key, evnt)} /></td>
                                <td><EditText value={p.date} type="date" name='date' onChange={(evnt) => handleChangeForm4(key, evnt)} /></td>
                                <td><EditText value={p.time} type="time" name='time' onChange={(evnt) => handleChangeForm4(key, evnt)} /></td>
                                <td><EditText value={p.vehicle} type="text" name='vehicle' onChange={(evnt) => handleChangeForm4(key, evnt)} /></td>
                                <td><EditText value={p.count} type="text" name='count' onChange={(evnt) => handleChangeForm4(key, evnt)} /></td>
                                <td><EditText value={p.details} type="text" name='details' onChange={(evnt) => handleChangeForm4(key, evnt)} /></td>
                                <td>
                                    <button onClick={() => updatePickup(key)}><Tooltip title="Update"><UpgradeIcon color="primary" /></Tooltip></button>
                                    <button onClick={() => deletePickup(key)}><Tooltip title="Delete"><DeleteForeverIcon color="secondary" /></Tooltip></button>
                                </td>
                            </tr>
                        ) : null}
                        
                    </tbody>
                </table>
            </div>
            <br></br>
            <div>
                <button onClick={() => addPickup()}><Tooltip title="Add"><AddTaskIcon color="success" /></Tooltip> Add new Pickup-Drop Bokking</button>
            </div>
            <br></br>
            <h2>Sightseeing Details</h2>
            <div style={{ 'overflow-x': 'scroll' }}>
                
                <table className="table table-striped table-bordered" style={{ 'width': 'max-content','min-width': '100%' }}>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Description/ID</th>
                            <th>Quantity</th>
                            <th>Cost</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(inputs5) ? inputs5.map((s, key) =>
                            <tr key={key}>
                                <td><EditText value={s.title} type="text" name='title' onChange={(evnt) => handleChangeForm5(key, evnt)} /></td>
                                <td><EditText value={s.date} type="date" name='date' onChange={(evnt) => handleChangeForm5(key, evnt)} /></td>
                                <td><EditText value={s.time} type="time" name='time' onChange={(evnt) => handleChangeForm5(key, evnt)} /></td>
                                <td ><EditTextarea value={s.details} type="text" name='details' onChange={(evnt) => handleChangeForm5(key, evnt)} /></td>
                                <td ><EditText value={s.count} type="text" name='count' onChange={(evnt) => handleChangeForm5(key, evnt)} /></td>
                                <td><EditText value={s.cost} type="text" name='cost' onChange={(evnt) => handleChangeForm5(key, evnt)} /></td>
                                <td>
                                    <button onClick={() => updateSight(key)}><Tooltip title="Update"><UpgradeIcon color="primary" /></Tooltip></button>
                                    <button onClick={() => deleteSight(key)}><Tooltip title="Delete"><DeleteForeverIcon color="secondary" /></Tooltip></button>
                                </td>
                            </tr>
                        ) : null}
                        
                    </tbody>
                </table>
            </div>
            <br></br>
            <div>
                <button onClick={() => addSight()}><Tooltip title="Add"><AddTaskIcon color="success" /></Tooltip>Add New Sightseeing Booking</button>
            </div>
            <br></br>
            <h2>Activities Details</h2>
            <div style={{ 'overflow-x': 'scroll' }}>

                <table className="table table-striped table-bordered" style={{ 'width': 'max-content', 'min-width': '100%' }}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Pickup Time</th>
                            <th>Description</th>
                            <th>Drop Time</th>
                            <th>Transfer</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(inputs7) ? inputs7.map((act, key) =>
                            <tr key={key}>
                                <td><EditText value={act.date} type="date" name='date' onChange={(evnt) => handleChangeForm7(key, evnt)} /></td>
                                <td><EditText value={act.pickuptime} type="time" name='pickuptime' onChange={(evnt) => handleChangeForm7(key, evnt)} /></td>
                                <td ><EditTextarea value={act.details} type="text" name='details' onChange={(evnt) => handleChangeForm7(key, evnt)} /></td>
                                <td><EditText value={act.droptime} type="time" name='droptime' onChange={(evnt) => handleChangeForm7(key, evnt)} /></td>
                                <td ><EditTextarea value={act.transfer} type="text" name='transfer' onChange={(evnt) => handleChangeForm7(key, evnt)} /></td>
                                <td>
                                    <button onClick={() => updateActivity(key)}><Tooltip title="Update"><UpgradeIcon color="primary" /></Tooltip></button>
                                    <button onClick={() => deleteActivity(key)}><Tooltip title="Delete"><DeleteForeverIcon color="secondary" /></Tooltip></button>
                                </td>
                            </tr>
                        ) : null}

                    </tbody>
                </table>
            </div>
            <br></br>
            <div>
                <button onClick={() => addActivity()}><Tooltip title="Add"><AddTaskIcon color="success" /></Tooltip>Add New Activity</button>
            </div>
            <br></br>
            <h2>Payment Details</h2>
            <div style={{ 'overflow-x': 'scroll' }}>

                <table className="table table-striped table-bordered" style={{ 'width': 'max-content','min-width':'100%' }}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Comment</th>
                            <th>Amount</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(inputs6) ? inputs6.map((p, key) =>
                            <tr key={key}>
                                <td><EditText value={p.date} type="date" name='date' onChange={(evnt) => handleChangeForm6(key, evnt)} /></td>
                                <td><EditText value={p.comment} type="text" name='comment' onChange={(evnt) => handleChangeForm6(key, evnt)} /></td>
                                <td><EditText value={p.amount} type="text" name='amount' onChange={(evnt) => handleChangeForm6(key, evnt)} /></td>
                                <td>
                                    <button onClick={() => updatePayment(key)}><Tooltip title="Update"><UpgradeIcon color="primary" /></Tooltip></button>
                                    <button onClick={() => deletePayment(key)}><Tooltip title="Delete"><DeleteForeverIcon color="secondary" /></Tooltip></button>
                                </td>
                            </tr>
                        ) : null}
                        <tr>
                            <td><EditText value={free.pydate} placeholder="Enter Date" type="date" name="pydate" onChange={handleChangeFree} /></td>
                            <td><EditText value={free.comment} placeholder="Enter Comments" type="text" name="comment" onChange={handleChangeFree} /></td>
                            <td><EditText value={free.amount} placeholder="Enter Amount" type="text" name="amount" onChange={handleChangeFree} /></td>
                            <td>
                                <button onClick={() => addPayment()}><Tooltip title="Add"><AddTaskIcon color="success" /></Tooltip>Add Payment</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}