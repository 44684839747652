import React from 'react';
import logo from './logo.png';
import 'bootstrap/dist/css/bootstrap.css';
export default function PdfHeader() {
    let newDate = new Date()
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month

    if (date_raw < 10) { date = "0" + date_raw.toString() } else { date = date_raw.toString() }
    if (month_raw < 10) { month = "0" + month_raw.toString() } else { month = month_raw.toString() }

    return (
        <div>
            <header>
                <nav className="navbar ">
                    <div className="navbar-header">
                        <h1 className="logo">
                            <img src={logo} height="150" width="120" style={{ paddingLeft: '10px' }} alt="DESTINATION VENTURE LLP" />
                        </h1>
                    </div>
                    <div><h2>TRAVEL ITINERARY</h2></div>
                    <div>
                        Date:{date}/{month}/{year}
                    </div>
                </nav>
            </header>
        </div>
    );
}
