import axios from "axios"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Header from './Header';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import CustomTablePagination, {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
export default function ListCust() {

    const [custs, setCust] = useState([]);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [custAdd, setCustAdd] = useState([]);

    useEffect(() => {
        getCusts();
    }, []);

    function getCusts() {
        const userdata = { 'userid': localStorage.getItem('userid'),'action':'list_custs' };
        axios.post('https://billing.destinationventure.com/api/operations.php',userdata).then(function (response) {
            console.log(response.data);
            setCust(response.data);
            setRows(response.data);
            setCustAdd(response.data);
        });
    }
    const addCust = () => {
        const data = { 'userid': localStorage.getItem('userid'), 'action': 'add_custs' };
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            getCusts();
        });
    }
    const deleteCust = (id) => {
        const data = { 'userid': localStorage.getItem('userid') ,'custid':id,'action':'delete_cust'};
        axios.post(`https://billing.destinationventure.com/api/operations.php`,data).then(function (response) {
            console.log(response.data);
            getCusts();
        });
    }
    const addCustSearched = (name,email,phone,gst) => {
        const data = { 'userid': localStorage.getItem('userid'), 'action': 'add_custs_searched','name':name,'email':email,'phone':phone,'gst':gst};
        axios.post(`https://billing.destinationventure.com/api/operations.php`, data).then(function (response) {
            console.log(response.data);
            getCusts();
        });
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterBySearch = (event) => {
        // Access input value
        const query = event.target.value;
        // Create copy of item list
        var updatedList = [...custs];
        
        // Trigger render with updated values
        setRows(updatedList.filter((item) => {
    return item.c_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        }));
    };
    const filterBySearchAdd = (event) => {
        // Access input value
        const query = event.target.value;
        // Create copy of item list
        var updatedList = [...custs];

        // Trigger render with updated values
        updatedList = updatedList.filter((item) => {
            return item.c_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        setCustAdd(updatedList);
    };
    return (
        
        <div>
            <Header />
            <div style={{'direction':'rtl'}} className="row">
                <div style={{ 'width': '25%', 'direction': 'ltr' }} class="input-group rounded">
                    <input type="search" class="form-control rounded" placeholder="Search" onChange={filterBySearch} aria-label="Search" aria-describedby="search-addon" />
                    <span class="input-group-text border-0" id="search-addon">
                        <ManageSearchIcon color='primary'/>
                    </span>
                </div>
            </div>
            <br></br>
            <div className="row">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) ? (rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row,key) => (
                            <tr key={key}>
                                <td>{row.c_name}</td>
                                <td>{row.c_email}</td>
                                <td>{row.c_phone}</td>
                                <td>
                                    <Link to={`edit/${row.c_id}`} style={{ marginRight: "10px" }} className="btn btn-info">Edit</Link>
                                    <button onClick={() => deleteCust(row.c_id)} style={{ marginRight: "10px" }} className="btn btn-danger" >Delete</button>
                                    <Link to={`/printinvoice/${row.c_id}`} className="btn btn-success" >Invoice</Link>
                                </td>
                            </tr>
                        )):null}

                    </tbody>
                    <tfoot>
                        <tr>
                            <CustomTablePagination
                                rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                                colSpan={4}
                                count={custs.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        'aria-label': 'rows per page',
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </tr>
                    </tfoot>
                </table>
            </div>
            <Popup trigger={<button> Add Customers </button>} modal>
                <div style={{ 'direction': 'rtl' }} className="row">

                    <div style={{ 'width': '100%', 'direction': 'ltr' }} class="input-group rounded">
                        <div style={{ 'padding-right': '10%' }}>
                            <button onClick={addCust} ><AddCircleOutlineIcon color="primary" />Add New Customer</button>
                        </div>
                        <input type="search" class="form-control rounded" placeholder="Search" onChange={filterBySearchAdd} aria-label="Search" aria-describedby="search-addon" />
                        <span class="input-group-text border-0" id="search-addon">
                            <ManageSearchIcon color='primary' />
                        </span>
                    </div>
                </div>
                <table className="table table-striped table-bordered" style={{ 'overflow-y': 'auto', 'height': '300px' }}>
                    {Array.isArray(custAdd) ? custAdd.map((row, key) => (
                        <tr key={key}>
                            <td>{row.c_name}</td>
                            <td>{row.c_email}</td>
                            <td>{row.c_phone}</td>
                            <td><button onClick={() => addCustSearched(row.c_name, row.c_email, row.c_phone, row.gst)} style={{ marginRight: "10px" }} className="btn" style={{ 'background-color': 'forestgreen' }}>Add</button></td>
                        </tr>
                    )) : null}
                </table>
            </Popup>
        </div>
    )
}